<template>
  <el-dialog v-model="dialogVisible" title="包厢列表" width="1000px" :close-on-click-modal="false">
    <div class="dialog-content">
      <!-- 左侧类型列表 -->
      <div class="type-list">
        <div class="type-item" :class="{ active: activeType === 'all' }" @click="handleTypeSelect('all')">
          全部 ({{ totalCount }})
        </div>
        <div
          v-for="type in packageTypes"
          :key="type.code"
          class="type-item"
          :class="{ active: activeType === type.code }"
          @click="handleTypeSelect(type.code)"
        >
          {{ type.name }} ({{ type.count }})
        </div>
      </div>

      <!-- 右侧包厢列表 -->
      <div class="package-content">
        <div class="header">
          <div class="title">{{ currentTypeName }}</div>
          <el-button type="primary" @click="handleAddPackage">添加包厢</el-button>
        </div>
        <div class="packages-grid">
          <div v-for="pkg in filteredPackages" :key="pkg.code" class="package-item" @click="handleEdit(pkg)">
            <div class="disabled-tag" v-if="!pkg.isShow">停用</div>
            <div class="package-name">{{ pkg.name }}</div>
            <div class="package-type">{{ pkg.typeName }}</div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>

  <!-- 添加包厢表单弹窗 -->
  <PackageForm
    v-model="formVisible"
    :form-data="currentForm"
    @success="handleFormSuccess"
    :store-code="storeCode"
    v-if="formVisible"
  />
</template>

<script>
import API from '../../../service/api'
import { ref, computed, defineComponent, watch } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import PackageForm from './PackageForm.vue'

export default defineComponent({
  name: 'PackageListDialog',
  components: {
    PackageForm,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    storeCode: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const dialogVisible = ref(props.modelValue)
    const formVisible = ref(false)
    const currentForm = ref(null)
    const activeType = ref('all')

    // 包厢类型数据
    const packageTypes = ref([])

    // 包厢数据
    const packages = ref([])

    // 计算当前类型名称
    const currentTypeName = computed(() => {
      if (activeType.value === 'all') return '全部'
      const type = packageTypes.value.find(t => t.code === activeType.value)
      return type ? type.name : ''
    })

    // 获取包厢列表
    const getBoxList = async () => {
      const res = await API.getBoxList({ storeCode: props.storeCode })
      if (res.code == 200) {
        const roomTypes = res.data.roomSaasHomeVOList || []
        // 更新包厢类型数据
        packageTypes.value = roomTypes.map(type => ({
          code: type.roomTypeCode,
          name: type.roomTypeName,
          count: type.count,
        }))

        // 更新包厢列表数据
        packages.value = roomTypes.flatMap(type =>
          (type.saasGroupVOList || []).map(pkg => ({
            code: pkg.code,
            roomCode: pkg.code,
            roomName: pkg.name,
            name: pkg.name,
            typeCode: type.roomTypeCode,
            roomTypeCode: type.roomTypeCode,
            typeName: type.roomTypeName,
            roomTypeName: type.roomTypeName,
            macAddress: pkg.roomMacKey,
            ipAddress: pkg.ip,
            isVirtual: pkg.isVirtual === 1,
            isShow: pkg.isShow === 1,
          })),
        )
      }
    }

    // 根据类型筛选包厢
    const filteredPackages = computed(() => {
      if (activeType.value === 'all') {
        return packages.value
      }
      return packages.value.filter(item => item.typeCode === activeType.value)
    })

    // 计算总数
    const totalCount = computed(() => packages.value.length)

    watch(
      () => props.modelValue,
      val => {
        if (val) {
          getBoxList()
        }
        dialogVisible.value = val
      },
    )

    watch(
      () => dialogVisible.value,
      val => {
        emit('update:modelValue', val)
      },
    )

    const handleClose = () => {
      dialogVisible.value = false
    }

    const handleTypeSelect = index => {
      activeType.value = index
    }

    const handleAddPackage = () => {
      currentForm.value = {
        code: '',
        name: '',
        typeCode: '',
        typeName: '',
        macAddress: '',
        ipAddress: '',
        isVirtual: false,
        status: true,
      }
      formVisible.value = true
    }

    const handleEdit = row => {
      currentForm.value = { ...row }
      formVisible.value = true
    }

    const handleDelete = row => {
      ElMessageBox.confirm('确定要删除该包厢吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const index = packages.value.findIndex(item => item.code === row.code)
        if (index > -1) {
          packages.value.splice(index, 1)
          ElMessage.success('删除成功')
        }
      })
    }

    const handleFormSuccess = formData => {
      formVisible.value = false
      getBoxList()
    }

    return {
      dialogVisible,
      formVisible,
      currentForm,
      activeType,
      packageTypes,
      totalCount,
      currentTypeName,
      filteredPackages,
      handleClose,
      handleTypeSelect,
      handleAddPackage,
      handleEdit,
      handleDelete,
      handleFormSuccess,
      packages,
    }
  },
})
</script>

<style lang="scss" scoped>
.dialog-content {
  display: flex;
  gap: 20px;
  height: 600px;

  .type-list {
    width: 200px;
    border-right: 1px solid #ebeef5;
    padding-right: 20px;

    .type-item {
      padding: 12px 16px;
      cursor: pointer;
      border-radius: 4px;
      margin-bottom: 8px;
      color: #606266;

      &:hover {
        background-color: #f5f7fa;
      }

      &.active {
        background-color: #409eff;
        color: white;
      }
    }
  }

  .package-content {
    flex: 1;
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .title {
        font-size: 16px;
        font-weight: bold;
      }
    }

    .packages-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
      gap: 16px;
      overflow-y: auto;

      .package-item {
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        padding: 16px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        overflow: hidden;
        height: 40px;

        &:hover {
          border-color: #409eff;
        }

        .disabled-tag {
          position: absolute;
          top: 0;
          right: 0;
          background: #909399;
          color: white;
          padding: 4px 16px;
          font-size: 12px;
          transform: rotate(45deg) translate(22%, -50%);
          transform-origin: center;
          width: 80px;
          text-align: center;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        .package-name {
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 8px;
        }

        .package-type {
          font-size: 14px;
          color: #909399;
        }
      }
    }
  }
}
</style>
